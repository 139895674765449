import React from "react"
import { graphql } from "gatsby"

import ChildPage from "../components/layout/page"
import Lightning from "../components/data/Lightning"
import GCAP2 from "../components/data/GCAP2"

const DataPage = ({ data }) => {

  const headerImage = data.header.publicURL

  const parallax = {
    image: headerImage,
    strength: 400
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "data", "title": "Data", "path": "/data" }
  ]
  return (
    <ChildPage
      title="Data"
      subtitle="Available for Download"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <GCAP2 />
    </ChildPage>
  )
}

export default DataPage

export const pageQuery = graphql`
  query DataPage {
    header: file(relativePath: { eq: "0sand1s.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
