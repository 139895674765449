import React from "react"


import Section from "../layout/section"
import { FixedContainer } from "../layout/container"

export default () => (
  <Section>
    <FixedContainer>
      <h4 className="title is-4">GCAP 2.0</h4>
      <h6 className="subtitle is-6">GEOS-Chem / GISS ModelE2.1 interface</h6>
      <article>
        <p>GCAP 2.0 is set for inclusion into the GEOS-Chem standard code in version 13.1.
           Until then, one may obtain the beta release code in version 13.0.0 using the following commands.
        </p>
        <pre>
          <code>
          git clone -b feature/GCAP2 https://github.com/ltmurray/GCClassic.git ./Code.GCAP2{'\n'}
          cd Code.GCAP2{'\n'}
          git submodule update --init --recursive{'\n'}
          cd src/GEOS-Chem{'\n'}
          git checkout feat/GCAP2{'\n'}
          cd ../HEMCO{'\n'}
          git checkout feat/GCAP2{'\n'}
          </code>
        </pre>
        <p>
          Note that submodules always download in a headless state, requiring one to manually select
          a branch as above.
        </p>
        <p>
          You may then follow the general directions for
          {` `}
          <a href="http://wiki.seas.harvard.edu/geos-chem/index.php/Creating_run_directories_for_GEOS-Chem_13.0.0_and_later">creating run directories</a>
          {` `}
          and
          {` `}
          <a href="http://wiki.seas.harvard.edu/geos-chem/index.php/Downloading_GEOS-Chem_data_directories">obtaining input files.</a>
        </p>
        <p>
          The GCAP 2.0 meteorology and emission files are located at
          {` `}
          <a href="http://atmos.earth.rochester.edu/input/gc/ExtData/">http://atmos.earth.rochester.edu/input/gc/ExtData/</a>,
          {` `}
          to which you can set remote_root in the download_data.py python script.
        </p>
        <p>
          Please contact
          {` `}
          <a href="mailto:lee.murray@rochester.edu?subject='GCAP 2.0'">Dr. Murray</a>
          {` `}
          with any questions or to report any issues.
        </p>
      </article>
    </FixedContainer>
  </Section>
)
